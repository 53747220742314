import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBfGtPe6eG_JRS8WLbCNymBoC5q3fJ94Hk',
  authDomain: 'codex-monte-carmelo.firebaseapp.com',
  projectId: 'codex-monte-carmelo',
  storageBucket: 'codex-monte-carmelo.appspot.com',
  messagingSenderId: '484785196819',
  appId: '1:484785196819:web:5bdf2e30620bbbf36f54e6',
  measurementId: 'G-ED9FDBEELV',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
